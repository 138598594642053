<template>
  <div class="aboutUs">
    <!-- 菜单 -->
    <my-nav id="myNav" pageName="About us"></my-nav>
    <div class="banner-box">
      <div class="banner-img">
        <div class="banner-title">YOUR PROFESSIONAL ONE-STOP</div>
        <div class="banner-title last">Auto Aftermarket Service Provider.</div>
        <div class="banner-text">BenBenUnion was founded in 2005 in Guangzhou, China.After 16 years of development, it has been focusing on the research and development of engine and chassis parts for Mercedes-Benz, BMW, Volkswagen, Audi and Porsche. </div>
      </div>
    </div>
    <div class="wrap">
      <div class="group-box clearfix">
        <img class="group-img" src="../../assets/images/aboutUsGroup.png" alt="">
        <div class="group-text">Benben Union was founded in 2005 in Guangzhou, China.After 16 years of development, it has been focusing on the research and development of engine and chassis parts for Mercedes-Benz, BMW, Volkswagen, Audi and Porsche. Benben Union is a professional automotive aftermarket supply chain management company that adheres to brand development and supervises OEM standards. With 16 years of intensive work in the industry and relying on this sound supply chain management system, Benben Union is committed to building the first cost-effective brand in the global automotive aftermarket. Adhering to the mission of "everything is centered on customer needs, and constantly meeting customer needs", we provide customers with one-stop, full-category, cost-effective solutions.At present, Benben Union's sales network has covered more than 40 countries and regions overseas, and has an in-depth understanding of the needs of car models in various countries. The product categories have exceeded 30,000+, covering suspension systems, steering systems, braking systems, etc.Benben Union has a complete after-sales guarantee and support system:</div>
      </div>
    </div>
    <!-- 页脚 -->
    <my-footer id="footer"></my-footer>
  </div>
</template>

<script>
import MyNav from "../../components/nav/nav";
import MyFooter from "../../components/footer/footer";
export default {
  name: "AboutUs",
  components: {
    MyNav,
    MyFooter,
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
.aboutUs {
  width: 100%;
  height: auto;
  background: #1A1A1A;
  .banner-box {
    background-image: linear-gradient(-90deg, #030309, #05030D);
    .banner-img {
      width: 100%;
      height: 500px;
      color: #fff;
      background: url("../../assets/images/bannerAboutUs.png") no-repeat center;
      background-size: auto 500px;
      .banner-title {
        width: 1200px;
        line-height: 60px;
        font-size: 46px;
        font-weight: bold;
        text-align: center;
        text-shadow: 4px 3px 4px #C02D31;
        // text-transform:uppercase;
        margin: 0 auto;
        padding-top: 60px;
        &.last {
          letter-spacing: 2px;
          padding-top: 0;
        }
      }
      .banner-text {
        width: 830px;
        line-height: 29px;
        font-size: 20px;
        text-align: justify;
        margin: 48px auto 0;
      }
    }
  }
  .wrap {
    width: 1200px;
    height: auto;
    margin: 0 auto;
  }
  .group-box {
    width: 100%;
    height: auto;
    padding: 99px 0 69px;
    .group-img {
      width: 708px;
      height: 495px;
      float: left;
    }
    .group-text {
      width: auto;
      height: auto;
      line-height: 26px;
      font-size: 16px;
      color: #fff;
      text-align: justify;
      margin-left: 731px;
    }
  }
}
</style>